import ProfileRepository from "./Api/ProfileRepository";
import DashboardRepository from "./Api/DashboardRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import RegionRepository from "./Api/RegionRepository";
import AgentRepository from "./Api/AgentRepository";
import OutletRepository from "./Api/OutletRepository";
import OutletSegmentRepository from "./Api/OutletSegmentRepository";
import ProductCategoryRepository from "./Api/ProductCategoryRepository";
import ProductBrandRepository from "./Api/ProductBrandRepository";
import ProductRepository from "./Api/ProductRepository";
import ProductReviewRepository from "./Api/ProductReviewRepository";
import PaymentMethodRepository from "./Api/PaymentMethodRepository";
import OrderRepository from "./Api/OrderRepository";
import PositionRepository from "./Api/PositionRepository";
import SalespersonRepository from "./Api/SalespersonRepository";
import AttendanceRepository from "./Api/AttendanceRepository";
import AttendanceGroupRepository from "./Api/AttendanceGroupRepository";
import AttendanceRemarkRepository from "./Api/AttendanceRemarkRepository";
import ActivityRepository from "./Api/ActivityRepository";
import ActivityTravelRepository from "./Api/ActivityTravelRepository";
import ConfigurationRepository from "./Api/ConfigurationRepository";
import WorkingScheduleRepository from "./Api/WorkingScheduleRepository";
import CallPlanConfigRepository from "./Api/CallPlanConfigRepository";
import ItemRepository from "./Api/ItemRepository";
import NoOrderReasonRepository from "./Api/NoOrderReasonRepository";
import NewsCategoryRepository from "./Api/NewsCategoryRepository";
import NewsRepository from "./Api/NewsRepository";
import OutletDraftRepository from "./Api/OutletDraftRepository";
import CallPlanRepository from "./Api/CallPlanRepository";
import ProductDisplayRepository from "./Api/ProductDisplayRepository";
import CountStockRepository from "./Api/CountStockRepository";
import MassBrandingItemRepository from "./Api/MassBrandingItemRepository";
import ProvinceRepository from "./Api/ProvinceRepository";
import DistrictRepository from "./Api/DistrictRepository";
import CommuneRepository from "./Api/CommuneRepository";
import VillageRepository from "./Api/VillageRepository";
import LeaveTypeRepository from "./Api/LeaveTypeRepository";
import LeaveBalanceRepository from "./Api/LeaveBalanceRepository";
import LeaveTransactionRepository from "./Api/LeaveTransactionRepository";
import OrderSummaryReportRepository from "./Api/OrderSummaryReportRepository";
import OutletRegistrationReportRepository from "./Api/OutletRegistrationReportRepository";
import ActiveOutletReportRepository from "./Api/ActiveOutletReportRepository";
import HolidayRepository from "./Api/HolidayRepository";
import DepartmentRepository from "./Api/DepartmentRepository";
import LeaveRequestRepository from "./Api/LeaveRequestRepository";
import SurveyRepository from "./Api/SurveyRepository";
import SurveyAnswerRepository from "./Api/SurveyAnswerRepository";
import SurveySectionRepository from "./Api/SurveySectionRepository";
import SurveyQuestionRepository from "./Api/SurveyQuestionRepository";
import LeaveRequestDetailRepository from "./Api/LeaveRequestDetailRepository";
import NotificationRepository from "./Api/NotificationRepository";
import NotificationTokenRepository from "./Api/NotificationTokenRepository";
import ProductPriceRepository from "./Api/ProductPriceRepository";
import SyncErpDataRepository from "./Api/SyncErpDataRepository";

const repositories = {
  'profile': ProfileRepository,
  'dashboard': DashboardRepository,
  'user': UserRepository,
  'role': RoleRepository,
  'permission': PermissionRepository,
  'file': FileRepository,
  'region': RegionRepository,
  'agent': AgentRepository,
  'outlet': OutletRepository,
  'outletSegment': OutletSegmentRepository,
  'productCategory': ProductCategoryRepository,
  'productBrand': ProductBrandRepository,
  'product': ProductRepository,
  'productReview': ProductReviewRepository,
  'paymentMethod': PaymentMethodRepository,
  'order': OrderRepository,
  'position': PositionRepository,
  'salesperson': SalespersonRepository,
  'attendance': AttendanceRepository,
  'attendanceGroup': AttendanceGroupRepository,
  'attendanceRemark': AttendanceRemarkRepository,
  'activity': ActivityRepository,
  'activityTravel': ActivityTravelRepository,
  'configuration': ConfigurationRepository,
  'workingSchedule': WorkingScheduleRepository,
  'callPlanConfig': CallPlanConfigRepository,
  'item': ItemRepository,
  'noOrderReason': NoOrderReasonRepository,
  'newsCategory': NewsCategoryRepository,
  'news': NewsRepository,
  'outletDraft': OutletDraftRepository,
  'callPlan': CallPlanRepository,
  'productDisplay': ProductDisplayRepository,
  'countStock': CountStockRepository,
  'massBrandingItem': MassBrandingItemRepository,
  'province': ProvinceRepository,
  'district': DistrictRepository,
  'commune': CommuneRepository,
  'village': VillageRepository,
  'leaveType': LeaveTypeRepository,
  'leaveBalance': LeaveBalanceRepository,
  'leaveTransaction': LeaveTransactionRepository,
  'orderSummaryReport': OrderSummaryReportRepository,
  'outletRegistrationReport': OutletRegistrationReportRepository,
  'activeOutletReport': ActiveOutletReportRepository,
  'holiday': HolidayRepository,
  'department': DepartmentRepository,
  'leaveRequest': LeaveRequestRepository,
  'survey': SurveyRepository,
  'surveyAnswer': SurveyAnswerRepository,
  'surveySection': SurveySectionRepository,
  'surveyQuestion': SurveyQuestionRepository,
  'leaveRequestDetail': LeaveRequestDetailRepository,
  'notification': NotificationRepository,
  'notificationToken': NotificationTokenRepository,
  'productPrice': ProductPriceRepository,
  'syncErpData': SyncErpDataRepository,
};

export default {
  get: name => repositories[name]
};
