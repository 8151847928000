import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/outlet`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  sync(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "sync",
      },
    });
  },
  export(payload, onDownloadProgress) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
      onDownloadProgress,
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store",
      },
    });
  },
  transfer(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "transfer",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  updateCallPlan(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update-call-plan",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "outlet_code",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_en",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_km",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries, "OR");
    let filters = {
      filters: [],
      filtertype: "AND",
    };
    payload.query.forEach(element => {
      filters.filters.push(element);
    });
    params.filters.push(filters);
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
