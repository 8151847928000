<template>
  <v-select
    :value="value"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :label="localization ? selectionLabelLocalize : selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="options"
    :loading="loading"
    :reduce="(item) => item[selectionKey]"
    class="n-async-multi-select"
    @input="input"
    :clearable="clearable"
  >
    <template #selected-option="item">
      <span v-if="translatable">
        {{ $t(item[selectionLabel]) }}
      </span>
      <span v-else>
        {{ item[localization ? selectionLabelLocalize : selectionLabel] }}
      </span>
    </template>
    <template #option="item">
      <span v-if="translatable">
        {{ $t(item[selectionLabel]) }}
      </span>
      <span v-else>
        {{ item[localization ? selectionLabelLocalize : selectionLabel] }}
      </span>
    </template>
    <template #no-options>
      {{ $t("field.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";
import { camelize } from "humps";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    value: {},
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectionKey: {
      type: String,
      default: "value",
    },
    selectionLabel: {
      type: String,
      default: "label",
    },
    initOptions: {
      type: String,
      default: "",
    },
    localization: {
      type: Boolean,
      default: false,
    },
    translatable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectionLabelLocalize() {
      return camelize(`${this.selectionLabel}_${this.$i18n.locale}`);
    },
  },
  data() {
    return {
      loading: false,
      loadedInitOptions: false,
    };
  },
  watch: {
    initOptions: function (value) {
      if (!this.loadedInitOptions) {
        this.selected = this.initOptions;
        this.loadedInitOptions = true;
      }
    },
  },
  methods: {
    reset() {
      this.selected = "";
    },
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>