export default [
  {
    path: '/leave-balance',
    name: 'list-leave-balance',
    component: () => import('@/views/leaveBalance/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveBalance',
      resource: 'leave-balance',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveBalance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leave-balance/allocate',
    name: 'allocate-leave-balance',
    component: () => import('@/views/leaveBalance/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveBalance',
      navActiveLink: 'list-leave-balance',
      resource: 'leave-balance',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveBalance',
          to: { name: 'list-leave-balance' }
        },
        {
          text: 'breadcrumb.allocateLeaveBalance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leave-balance/mass-allocate',
    name: 'mass-allocate-leave-balance',
    component: () => import('@/views/leaveBalance/massAllocation/Allocation.vue'),
    meta: {
      pageTitle: 'breadcrumb.leaveBalance',
      navActiveLink: 'list-leave-balance',
      resource: 'leave-balance',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listLeaveBalance',
          to: { name: 'list-leave-balance' }
        },
        {
          text: 'breadcrumb.massAllocateLeaveBalance',
          active: true,
        },
      ],
    },
  },
]
