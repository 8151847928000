export default {
  namespaced: true,
  state: {
    outletSegment: [],
    outlet: [],
    outletDraft: [],
    productCategory: [],
    productBrand: [],
    product: [],
    item: [],
    agent: [],
    noOrderReason: [],
    salesperson: [],
    position: [],
    workingSchedule: [],
    user: [],
    role: [],
    attendance: [],
    attendanceGroup: [],
    activity: [],
    activityTravel: [],
    paymentMethod: [],
    order: [],
    newsCategory: [],
    news: [],
    callPlan: [],
    productDisplay: [],
    countStock: [],
    massBranding: [],
    leaveType: [],
    leaveBalance: [],
    leaveTransaction: [],
    orderSummaryReport: [],
    callPlanOrder: [],
    holiday: [],
    department: [],
    leaveRequest: [],
    survey: [],
    marketSurvey: [],
    leaveRequestDetail: [],
    outletFeedbackSurvey: [],
    productPrice: [],
  },
  getters: {},
  mutations: {
    SET_OUTLET_SEGMENT_COLUMN(state, val) {
      state.outletSegment = val
    },
    SET_OUTLET_COLUMN(state, val) {
      state.outlet = val
    },
    SET_OUTLET_DRAFT_COLUMN(state, val) {
      state.outletDraft = val
    },
    SET_PRODUCT_CATEGORY_COLUMN(state, val) {
      state.productCategory = val
    },
    SET_PRODUCT_BRAND_COLUMN(state, val) {
      state.productBrand = val
    },
    SET_PRODUCT_COLUMN(state, val) {
      state.product = val
    },
    SET_ITEM_COLUMN(state, val) {
      state.item = val
    },
    SET_AGENT_COLUMN(state, val) {
      state.agent = val
    },
    SET_NO_ORDER_REASON_COLUMN(state, val) {
      state.noOrderReason = val
    },
    SET_SALESPERSON_COLUMN(state, val) {
      state.salesperson = val
    },
    SET_POSITION_COLUMN(state, val) {
      state.position = val
    },
    SET_WORKING_SCHEULDE_COLUMN(state, val) {
      state.workingSchedule = val
    },
    SET_USER_COLUMN(state, val) {
      state.user = val
    },
    SET_ROLE_COLUMN(state, val) {
      state.role = val
    },
    SET_ATTENDANCE_COLUMN(state, val) {
      state.attendance = val
    },
    SET_ATTENDANCE_GROUP_COLUMN(state, val) {
      state.attendanceGroup = val
    },
    SET_ACTIVITY_COLUMN(state, val) {
      state.activity = val
    },
    SET_ACTIVITY_TRAVEL_COLUMN(state, val) {
      state.activityTravel = val
    },
    SET_PAYMENT_METHOD_COLUMN(state, val) {
      state.paymentMethod = val
    },
    SET_ORDER_COLUMN(state, val) {
      state.order = val
    },
    SET_NEWS_CATEGORY_COLUMN(state, val) {
      state.newsCategory = val
    },
    SET_NEWS_COLUMN(state, val) {
      state.news = val
    },
    SET_CALL_PLAN_COLUMN(state, val) {
      state.callPlan = val
    },
    SET_PRODUCT_DISPLAY_COLUMN(state, val) {
      state.productDisplay = val
    },
    SET_COUNT_STOCK_COLUMN(state, val) {
      state.countStock = val
    },
    SET_MASS_BRANDING_COLUMN(state, val) {
      state.massBranding = val
    },
    SET_LEAVE_TYPE_COLUMN(state, val) {
      state.leaveType = val
    },
    SET_LEAVE_BALANCE_COLUMN(state, val) {
      state.leaveBalance = val
    },
    SET_LEAVE_TRANSACTION_COLUMN(state, val) {
      state.leaveTransaction = val
    },
    SET_ORDER_SUMMARY_REPORT_COLUMN(state, val) {
      state.orderSummaryReport = val
    },
    SET_CALL_PLAN_ORDER_COLUMN(state, val) {
      state.callPlanOrder = val
    },
    SET_HOLIDAY_COLUMN(state, val) {
      state.holiday = val
    },
    SET_DEPARTMENT_COLUMN(state, val) {
      state.department = val
    },
    SET_LEAVE_REQUEST_COLUMN(state, val) {
      state.leaveRequest = val
    },
    SET_SURVEY_COLUMN(state, val) {
      state.survey = val
    },
    SET_MARKET_SURVEY_COLUMN(state, val) {
      state.marketSurvey = val
    },
    SET_LEAVE_REQUEST_DETAIL_COLUMN(state, val) {
      state.leaveRequestDetail = val
    },
    SET_OUTLET_FEEDBACK_SURVEY_COLUMN(state, val) {
      state.outletFeedbackSurvey = val
    },
    SET_PRODUCT_PRICE_COLUMN(state, val) {
      state.productPrice = val
    },
  },
  actions: {},
}
